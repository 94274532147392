<template>

    <div class="bigBox">
        <div class="grzxTopBox">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/Icon(1)_1686298472325.png" class="backIcon" @click="toLink('index')">
            <p class="grzxTopBoxTitle">个人中心</p>
        </div>

        <div class="grzxBox1" v-if="wjsUserInfo">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/企业微信截图_16863770752003_1686377167681.png" class="grzxAvatar">
            <p class="grzxName">用户{{wjsUserInfo.username}}</p>
        </div>

        <div class="grzxBox1" v-else @click="toLink('dl')">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/企业微信截图_16863770752003_1686377167681.png" class="grzxAvatar">
            <p class="grzxName">登录账号</p>
        </div>

        <div class="grzxBox2">
            <div class="grzxBox2Item" @click="toLink2('zkxm')">
                <p class="grzxBox2ItemP1">0</p>
                <p class="grzxBox2ItemP2">收藏文章</p>
            </div>
            <div class="grzxBox2Item" @click="toLink2('wdsc')">
                <p class="grzxBox2ItemP1">0</p>
                <p class="grzxBox2ItemP2">收藏项目</p>
            </div>
            <div class="grzxBox2Item" @click="toLink2('ptxx')">
                <p class="grzxBox2ItemP1">0</p>
                <p class="grzxBox2ItemP2">消息通知</p>
            </div>
        </div>

        <div class="grzxBox3">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组740_1686542553878.png">
            <div class="grzxBox3Btn" @click="toLink('jrpf')">立即查看</div>
        </div>

        <div class="grzxBox4">
            <div class="grzxBox4Title">
                <p class="grzxBox4TitleP1">我的项目</p>
                <!-- <div class="grzxBox4TitleBox" @click="toLink2('wdxm')">
                    <p class="grzxBox4TitleP2">查看更多</p>
                </div> -->
                <div class="grzxBox4TitleBox">
                    <p class="grzxBox4TitleP2">查看更多</p>
                </div>
            </div>
            <div class="grzxBox4List">
                <div class="grzxBox4Item">
                    <p class="grzxBox4ItemP1">项目数量（个）</p>
                    <p class="grzxBox4ItemP2">0</p>
                </div>
                <div class="grzxBox4Item">
                    <p class="grzxBox4ItemP1">已完成</p>
                    <p class="grzxBox4ItemP2">0</p>
                </div>
                <div class="grzxBox4Item">
                    <p class="grzxBox4ItemP1">进行中</p>
                    <p class="grzxBox4ItemP2">0</p>
                </div>
            </div>
        </div>

        <div class="grzxBox5">
            <p class="grzxBox5Title">其他功能</p>
            <div class="grzxBoxList">
                <div class="grzxBoxListItem">
                    <img class="grzxBoxListItemImg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组743_1686564715823.png">
                    <p class="grzxBoxListItemP">帮助中心</p>
                </div>
                <div class="grzxBoxListItem">
                    <img class="grzxBoxListItemImg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组742_1686549142314.png">
                    <p class="grzxBoxListItemP">邀请好友</p>
                </div>

                <div class="grzxBoxListItem" @click="tcdl">
                    <img class="grzxBoxListItemImg" src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组753_1686793236877.png">
                    <p class="grzxBoxListItemP">退出登录</p>
                </div>
                
            </div>
        </div>


        <div class="grzxBox6">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/734b9080467f1a3173fb486a8e8f971_1686379980516.png" class="grzxBox6Img">
        </div>

    </div>



</template>

<script>
import Cookies from 'js-cookie'
import {getListColumn,getChildColumn,getArticleAndProject,getListBanner,getlistOnHomepage,getlistOnHomepage2,sms,phoneRegister} from "@/api/index"
import { Loading ,Message } from 'element-ui';
import store from '@/store'

export default {

    data(){
        return {
            load : null,
            wjsToken: null,
            wjsUserInfo: null,
        }
    },
    methods:{
        toBack(){
            this.$router.back(-1)
        },
        toLink(name){
            // if(name == "yszb"){
            //     //window.location.href="http://www.zygart.cn"; 
            // }else{
                //this.reset()
                this.$router.push({name:name});
            //}
        },
        toLink2(name){
                if(this.wjsToken&&this.wjsUserInfo){
                    this.$router.push({name:name});
                }else{
                    this.showErr('请登录')
                    this.toLink('dl');
                }
                
        },
        tcdl(){
                if(this.wjsToken&&this.wjsUserInfo){
                    Cookies.remove('wjsToken')
                    Cookies.remove('wjsUserInfo')
                    this.wjsToken =  null;
                    this.wjsUserInfo =  null;
                    this.showSuccess('已退出登录')
                }else{

                }
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },

        showErr(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '网络错误',
                spinner: 'el-icon-circle-close',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

        showSuccess(txt){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: txt || '成功',
                spinner: 'el-icon-circle-check',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.load && this.load.close(); 
            },2000)
        },

            

    },
    mounted(){
        this.wjsToken =  Cookies.get('wjsToken') || null;
        this.wjsUserInfo =  Cookies.get('wjsUserInfo') || null;
        this.wjsUserInfo = JSON.parse(this.wjsUserInfo);

        console.log(this.wjsToken)
        console.log(this.wjsUserInfo)

        if(this.wjsToken&&this.wjsUserInfo){
            this.showLoading()
            this.load && this.load.close();
        }else{
            this.showErr('请登录')
            this.toLink('dl');
        }

        
    }

}




</script>





<style lang="scss" scoped>
.bigBox{
    width: 100%;
    height: 100%;
    background-color: #fff;

    .grzxTopBox{
        height: .88rem;
        width: 100%;
        position: relative;
        .backIcon{
            height: .48rem;
            width: .48rem;
            position: absolute;
            top: .2rem;
            left: .4rem;
        }
        .grzxTopBoxTitle{
            font-size: .34rem;
            color: #343434;
            text-align: center;
            line-height: 0.88rem;
        }
    }
    .grzxBox1{
        display: flex;
        padding: .2rem .32rem;
        box-sizing: border-box;
        .grzxAvatar{
            box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.12);
            border: .02rem solid rgba(0,0,0,0.12);
            border-radius: 50%;
            width: 1.28rem;
            height: 1.28rem;
            display: block;
            object-fit: cover;
            padding: .04rem;
            box-sizing: border-box;
            margin-right: .26rem;
        }
        .grzxName{
            font-size: .36rem;
            color: #343434;
            white-space: nowrap;
        }
    }
    .grzxBox2{
        display: flex;
        padding: .34rem .74rem;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        .grzxBox2Item{
            .grzxBox2ItemP1{
                font-size: .4rem;
                font-weight: bold;
                color: #343434;
                text-align: center;
                white-space: nowrap;
            }
            .grzxBox2ItemP2{
                font-size: .26rem;
                color: #343434;
                text-align: center;
                white-space: nowrap;
            }
        }
    }
    .grzxBox3{
        margin-bottom: .44rem;
        padding: 0 .32rem;
        box-sizing: border-box;
        position: relative;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        .grzxBox3Btn{
            background: linear-gradient(91deg, #EFDBB5 0%, #EBE5C0 100%);
            border-radius: .28rem;
            font-size: .24rem;
            color: #53452C;
            height: .54rem;
            width: 1.48rem;
            text-align: center;
            line-height: .54rem;
            position: absolute;
            right: .88rem;
            top: .2rem;
        }
    }
    .grzxBox4{
        padding: 0 .32rem;
        box-sizing: border-box;
        margin-bottom: .4rem;
        .grzxBox4Title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: .22rem;
            .grzxBox4TitleP1{
                font-size: .3rem;
                font-weight: bold;
                color: #343434;
            }
            .grzxBox4TitleBox{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .grzxBox4TitleP2{
                    font-size: 13px;
                    color: #A5A5A5;
                }
            }
        }
        .grzxBox4List{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .grzxBox4Item{
                width: 2.2rem;
                height: 1.24rem;
                padding: .12rem;
                box-sizing: border-box;
                background: #FFFFFF;
                box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.12);
                border-radius: .06rem;
                .grzxBox4ItemP1{
                    font-size: .26rem;
                    color: #A5A5A5;
                    white-space: nowrap;
                    margin-bottom: .16rem;
                }
                .grzxBox4ItemP2{
                    font-size: .34rem;
                    font-weight: bold;
                    color: #343434;
                    white-space: nowrap;
                }
            }
        }
    }
    .grzxBox5{
        margin-bottom: .6rem;
        .grzxBox5Title{
            font-size: .3rem;
            font-weight: bold;
            color: #343434;
            padding: 0 .32rem;
            box-sizing: border-box;
            margin-bottom: .4rem;
        }
        .grzxBoxList{
            display: flex;
            align-items: center;
            padding: 0 .32rem;
            box-sizing: border-box;
            .grzxBoxListItem{
                margin-right: .8rem;
                overflow: hidden;
                position: relative;
                .grzxBoxListItemImg{
                    height: .52rem;
                    width: .52rem;
                    object-fit: cover;
                    margin: 0 auto;
                    display: block;
                    margin-bottom: .12rem;
                }
                .grzxBoxListItemP{
                    font-size: .26rem;
                    color: #343434;
                    white-space: nowrap;
                    text-align: center;
                }
            }
        }
    }

    .grzxBox6{
        padding: 0 .32rem;
        box-sizing: border-box;
        .grzxBox6Img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }


}




</style>